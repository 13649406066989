import MotorsportsPage from '../components/MotorsportsPage'
import { graphql } from 'gatsby'

export const query = graphql`
  query {
    contentfulMotorsports {
      heroImage {
        description
        file {
          url
        }
      }
      sponsors {
        logo {
          description
          file {
            url
          }
        }
        secondImage {
          description
          file {
            url
          }
        }
        description {
          description
        }
      }
    }
  }
`
export default MotorsportsPage
