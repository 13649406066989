import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import styles from './MotorsportsPage.module.scss'
import Picture from '@components/Picture'
import { parseImage } from '@utils/contentful'
import { TransitionState } from 'gatsby-plugin-transition-link'
import Layout from '@components/Layout'
import Text from '@components/Text'

const cx = classnames.bind(styles)

const MotorsportsPage = ({ data }) => {
  const { contentfulMotorsports } = data
  const [, setOpacityStyle] = useState()
  const handleOpacityChange = style => {
    setOpacityStyle(style)
  }

  let sponsors = contentfulMotorsports.sponsors.map(item => ({
    ...item,
    logo: item.logo && parseImage(item.logo),
    secondImage: item.secondImage && parseImage(item.secondImage),
  }))

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => {
        return (
          <Layout
            className={transitionStatus}
            onOpacityChange={handleOpacityChange}
            seo={contentfulMotorsports.header}
            smoothScroll={true}
          >
            <div className={cx('container')}>
              <div className={cx('hero-container')}>
                <Text tag="h1" type="h1" className={cx('dynamic-font-size', 'page-title')}>
                  {`Gainbridge Motorsport Sponsorships`}
                </Text>
                <div className={cx('hero-bottom-con')}>
                  <div className={cx('hero-img-container')}>
                    <Picture
                      className={cx('hero-img')}
                      src={contentfulMotorsports.heroImage.file.url}
                      alt={contentfulMotorsports.heroImage.description}
                    />
                  </div>
                  <div className={cx('hero-text-container')}>
                    {/* <Text tag="h1" type="h1" className={cx('neg-margin-top', 'dynamic-font-size')}>
                    {`Sponsorships`}
                  </Text> */}
                    <div className={cx('hero-small-text-container')}>
                      <Text tag="h3" type="h3" className={cx('hero-small-text', 'inline')}>
                        {`
                        Gainbridge seeks to align our investment in motorsports with our commitment to creating positive
                        change in communities by engaging diverse groups and younger generations in STEM careers through
                        these sponsorships.
                        `}
                      </Text>
                      {/* <Text tag="h3" type="h3" className={cx('hero-small-text', 'italic', 'inline')}>
                      {`
                        "Group 1001 and our subsidiaries have a strong commitment to service and community transformation.
                        Education and sports initiatives, coupled with impactful partnerships, allow Group 1001 to improve
                        lives through positive change in our communities."
                        `}
                    </Text>
                    <Text tag="h3" type="h3" className={cx('hero-small-text')}>
                      {`
                        — Dan Towriss, CEO & President, Group 1001
                        `}
                    </Text> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className={cx('sponsors-container')}>
                {sponsors &&
                  sponsors.map(item => {
                    if (item.logo && item.description) {
                      return (
                        <div className={cx('sponsor-container')} key={item.logo.url}>
                          <div className={cx('left-side')}>
                            <div className={cx('logo-container')}>
                              <Picture className={cx('sponsor-logo')} src={item.logo.url} alt={item.logo.description} />
                            </div>

                            <Text className={cx('description')} tag="b1" type="b1">
                              {item.description.description}
                            </Text>
                          </div>
                          <div className={cx('right-side')}>
                            <Picture
                              className={cx('second-image')}
                              src={item.secondImage.url}
                              alt={item.secondImage.description}
                            />
                          </div>
                        </div>
                      )
                    }
                  })}
              </div>
            </div>
          </Layout>
        )
      }}
    </TransitionState>
  )
}

MotorsportsPage.propTypes = {
  data: PropTypes.object,
}

export default MotorsportsPage
